import * as ko from 'knockout';

import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { session } from '../session';

import { ListLoaderDelegate } from '../components/list_loader';
import { ListRequestParams } from '../api/request';
import { DownloadableReport } from '../models/downloadable_report';
import { Action } from '../components/basic_widgets';
import { sanitizeUrl } from '@braintree/sanitize-url';
import {  retrieveDownloadableReportLayouts, DownloadableReportLayoutData, deleteDownloadableReportLayout } from '../api/downloadable_report';

let template = require('raw-loader!../../templates/global_report_templates.html').default;

class ReportTemplatesScreen implements ListLoaderDelegate<DownloadableReportLayoutData, DownloadableReport> {
  isAdmin = session.isAdmin();
  listActions = ko.observableArray<ListHeaderAction>([]);
  loading = ko.observable(false);

  constructor() {
    if (this.isAdmin) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/global_report_templates/new/',
        tooltipTitle: i18n.t('Add Report Template')(),
      });
    }
  }
  fetch(params: ListRequestParams) {
    return retrieveDownloadableReportLayouts();
  }

  instantiate(data: DownloadableReportLayoutData) {
    return new DownloadableReport(data);
  }

  remove(id: any): Promise<any> {
    return deleteDownloadableReportLayout(id);
  }

  canRemove(entity: DownloadableReport) {
    return this.isAdmin;
  }

  getEditUrl(entity: DownloadableReport): string {
    return `/global_report_templates/${entity.id()}`;
  }

  getActions(entity: DownloadableReport): Action[] {
    return [
      {
        title: i18n.t('Download')(),
        icon: 'file_download',
        onClick: () => {
          window.location.href = sanitizeUrl(entity.fileUrl());
        },
        cssClass: '',
      }
    ]
  }
}

export let reportTemplatesScreen = {
  name: 'report-templates-screen',
  viewModel: ReportTemplatesScreen,
  template: template,
};

ko.components.register(reportTemplatesScreen.name, reportTemplatesScreen);
