import { FileUploadDelegate } from './components/basic_widgets';

export interface FileUploadEndpoint {
  file_name: string;
  file_public_url: string;
  upload_url: string;
  upload_headers: { [key: string]: string };
}

export interface CloudStorageUploadDelegate<T = void> {
  canReuseEndpoint: boolean;
  getUploadEndpoint(contentType: string, options?: T): Promise<FileUploadEndpoint>;
  onFileUploaded(
    userFileName: string,
    fileName: string,
    publicURL: string,
    contentType: string,
    context: any
  ): void;
}

export class CloudStorageUpload<T extends { file_name: string } > implements FileUploadDelegate {
  uploadEndpoint: FileUploadEndpoint;
  uploading = ko.observable(false);
  fileUploadError = ko.observable(false);
  uploadEndpointParams: T = undefined;

  constructor(private delegate: CloudStorageUploadDelegate<T>) {}

  setUploadEndpointParams(params: T) {
    this.uploadEndpointParams = params;
  }

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest,
    context?: any
  ) => {
    let uploadEndpointPromise: Promise<FileUploadEndpoint>;
    if (
      this.delegate.canReuseEndpoint &&
      this.uploadEndpoint &&
      this.uploadEndpoint.upload_headers['Content-Type'] === contentType
    ) {
      uploadEndpointPromise = Promise.resolve(this.uploadEndpoint);
    } else {
      uploadEndpointPromise = this.delegate
        .getUploadEndpoint(contentType, this.uploadEndpointParams)
        .then((data) => {
          this.uploadEndpoint = data;
          return data;
        });
    }

    this.fileUploadError(false);
    this.uploading(true);
    uploadEndpointPromise
      .then((uploadEndpoint) => {
        $.ajax(uploadEndpoint.upload_url, {
          method: 'PUT',
          data: fileContents,
          processData: false,
          headers: uploadEndpoint.upload_headers,
          xhr: prepareXHR,
          success: () => {
            this.uploading(false);
            this.delegate.onFileUploaded(
              userFileName,
              this.uploadEndpoint.file_name,
              this.uploadEndpoint.file_public_url,
              contentType,
              context
            );
          },
          error: () => {
            this.fileUploadError(true);
            this.uploading(false);
          },
        });
      })
      .catch(() => {
        this.fileUploadError(true);
        this.uploading(false);
      });
  };
}
